<template>
    <div class="loader-wrapper" v-show="isLoading">
        <loader></loader>
    </div>
</template>

<script>
import httpIntratone from "@/services/httpIntratone.js"
import Loader from "@/components/basic/Loader.vue"
import { getHomeInformations } from "@/services/intratone/home.js"
import { getUserRight } from "@/services/intratone/right.js"

import navigation from "@/mixins/navigation"

import { useToast } from "vue-toastification"
const toast = useToast()

export default {
    name: "ConsoleLogin",
    components: {
        Loader,
    },
    mixins: [navigation],
    data() {
        return {
            // Show loader
            isLoading: true,
        }
    },
    methods: {
        async login(dataLog) {
            try {
                // Requesting v1 site for user authentification
                httpIntratone
                    .post("connexion.php", dataLog)
                    .then((response) => {
                        if (response.status !== 200) {
                            throw new Error("Cannot access intratone.info")
                        }
                        if (response.data.error === 1) {
                            if (response.data.message === "") {
                                throw new Error(
                                    this.$t("alerts.connection.failed")
                                )
                            } else {
                                throw new Error(
                                    this.$t("alerts.connection.invalid")
                                )
                            }
                        }

                        // Storing user state
                        if (response.data.session) {
                            this.$store.commit("auth/setJwtValues", {
                                jwt: response.data.session.jwt,
                                expireAt: response.data.session.jwt_expire,
                                jwtRenew: response.data.session.renew,
                                renewExpireAt:
                                    response.data.session.renew_expire,
                            })
                            localStorage.setItem(
                                "apiintra_jwt",
                                response.data.session.jwt
                            )
                            localStorage.setItem(
                                "apiintra_jwt_expire",
                                response.data.session.jwt_expire
                            )
                            localStorage.setItem(
                                "apiintra_renew_expire",
                                response.data.session.renew_expire
                            )
                            localStorage.setItem('csrf', response.data.CSRF)
                            this.$store.commit("auth/setLogged", true)
                        }

                        this.$store.commit("account/setId", response.id)
                        if (response.data.define) {
                            this.$store.commit(
                                "account/setDefine",
                                response.data.define
                            )
                            this.$store.commit(
                                "account/setCountry",
                                response.data.define.country
                            )
                        }

                        response.data.ROBOT
                            ? this.$store.commit("account/setIpass", true)
                            : null

                        this.$store.commit("auth/setLogged", true)
                        // TEMP
                        if (response.data.SID) {
                            this.$store.commit(
                                "auth/setIntratoneDev2SID",
                                response.data.SID
                            )
                        }

                        // Requesting API for user rights
                        getUserRight().then((userRightsResponse) => {
                            if (
                                userRightsResponse === false ||
                                userRightsResponse.data.error
                            ) {
                                throw new Error(
                                    this.$t("alerts.connection.failed")
                                )
                            } else {
                                const rightList = []
                                userRightsResponse.data.rights.list.forEach(
                                    (e) => {
                                        rightList.push(e.define)
                                    }
                                )
                                this.$store.commit("auth/setRights", rightList)

                                const privilegesList = []
                                userRightsResponse.data.privileges.list.forEach(
                                    (e) => {
                                        privilegesList.push(e.keyname)
                                    }
                                )
                                this.$store.commit(
                                    "auth/setPrivileges",
                                    privilegesList
                                )

                                this.$store.commit(
                                    "account/setLogin",
                                    userRightsResponse.data.compte
                                )

                                this.$store.commit(
                                    "account/setAdmin",
                                    rightList.length === 1 &&
                                        rightList[0] === "ADMIN"
                                )
                            }

                            // Requesting API for global account informations
                            const params = {
                                user: +true,
                                messages: "unread",
                            }
                            getHomeInformations(params).then((homeResponse) => {
                                if (userRightsResponse === false) {
                                    throw new Error(
                                        this.$t("alerts.connection.failed")
                                    )
                                }
                                // Redirecting
                                if (response.data.define["ACCUEIL"] === "0") {
                                    if (this.checkRight("G_RESIDENCES")) {
                                        this.$router.push({
                                            name: "Residences",
                                        })
                                    } else if (
                                        this.checkRight("G_TABL_GERE") ||
                                        this.checkRight("G_TABL_VISU")
                                    ) {
                                        this.$router.push({
                                            name: "V1BulletinBoard",
                                        })
                                    } else {
                                        this.$router.push({
                                            name: "V1",
                                            params: {
                                                arg1: "data",
                                                arg2: "accueil",
                                                arg3: "accueil",
                                            },
                                        })
                                    }
                                } else {
                                    this.$router.push({
                                        name: "V1",
                                        params: {
                                            arg1: "data",
                                            arg2: "accueil",
                                            arg3: "accueil",
                                        },
                                    })
                                }
                                // Storing global account informations
                                this.$store.commit(
                                    "account/setFirstName",
                                    homeResponse.data.user.prenom
                                )
                                this.$store.commit(
                                    "account/setLastName",
                                    homeResponse.data.user.nom
                                )
                                // Alert : connected
                                toast.success(
                                    this.$t("alerts.connection.connected", {
                                        name: this.$store.getters[
                                            "account/getFullName"
                                        ],
                                    }),
                                    {
                                        icon: "fas fa-power-off",
                                    }
                                )
                                // Alert : notifications
                                if (
                                    this.$store.getters[
                                        "account/getNotifications"
                                    ] > 0
                                ) {
                                    toast.info(
                                        this.$t("alerts.notifications", {
                                            count: this.$store.getters[
                                                "account/getNotifications"
                                            ],
                                        }),
                                        {
                                            icon: "fas fa-bell",
                                        }
                                    )
                                }
                            })
                        })
                    })
                    .catch((error) => {
                        toast.error(error.message, {
                            icon: "fas fa-exclamation-circle",
                            timeout: 10000,
                        })

                        this.$router.push({
                            name: "Login",
                        })
                    })
            } catch (error) {
                console.log(error)
                toast.error(error.message, {
                    icon: "fas fa-exclamation-circle",
                    timeout: 10000,
                })

                this.$router.push({
                    name: "Login",
                })
            }
        },

        loginConsole() {
            const data_console = JSON.parse(this.$route.query.params)
            var params = "console=1"
            if (
                data_console.uid !== "" &&
                data_console.user_id !== "" &&
                data_console.source_id !== ""
            ) {
                data_console["console"] = 1
                params += "&uid=" + data_console.uid
                params += "&user_id=" + data_console.user_id
                params += "&source_id=" + data_console.source_id
                this.login(params)
            } else {
                toast.error("Login Failed", {
                    icon: "fas fa-exclamation-circle",
                    timeout: 10000,
                })

                this.$router.push({
                    name: "Login",
                })
            }
        },
    },
    async mounted() {
        this.$store.commit("disconnect")
        localStorage.clear()
        if (window.IdSession) {
            let data = {
                idsession: window.IdSession,
                btn: window.btn,
                ASSIST: window.ASSIST,
                Jules: window.JulesClick,
                Resume: window.ResumeClick,
                num_version_user: window.num_version_user,
            }
            httpIntratone.post("data/deconnect.php", data).then(() => {
                this.loginConsole()
            })
        } else {
            this.loginConsole()
        }
    },
}
</script>

<style lang="scss" scoped>
.loader-wrapper {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
